body {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.home-card {
  flex: 1 1 0;
  transition: 250ms cubic-bezier(0, 0, 0, 1);
  min-width: 440px;
}

.home-card:hover {
  transform: translateY(-4px);
}

.card-holder {
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  padding-top: 20px;
  inset-block-start: calc(100% - 48px);
  grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
  max-inline-size: 1440px;
  inline-size: 90vw;
  padding-bottom: 32px;
  padding-left: 10px;
  padding-right: 10px;
}

.address {
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding-bottom: 15px;
  padding-top: 15px;
}

.logo-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-wrapper {
  column-gap: 15px;
  display: flex;
}

.sign-in-dialog {
  max-width: 360px !important;
}

.header-inner {
  inline-size: unset;
  max-inline-size: unset;
  padding-inline: 16px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.control-panel-layout {
  grid-area: main;
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "sidenav main-control";
  height: 100vh;
  overflow: auto;
}

.base-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;
  overflow: hidden;
}

.header {
  grid-area: header;
}

.sidenav {
  grid-area: sidenav;
  padding: 5px;
}

.main {
  grid-area: main-control;
  overflow-y: auto;
}

.main-home {
  grid-area: main;
  margin: auto;
  overflow: auto;
}

.main-cards {
  column-count: 2;
  gap: 16px;
  margin: 16px;
}

.displayNone {
  display: none !important;
}

.card {
  margin-bottom: 16px;
}

@media only screen and (min-width: 65.625em) {

  /* Break out main cards into two columns */
  .main-cards {
    column-count: 2;
  }
}

.ScheduleItem {
  display: grid;
  grid-template-columns: auto 32px;
  padding-left: 10px;
  line-height: 32px;
  align-items: center;
}


.ScheduleItem>.Property {
  margin-right: 10px;
}

.ScheduleItemCard Label {
  line-height: 30px;
}

.ScheduleItem>.Menu {}